<template>
    <node-view-wrapper
        as="div"
        class="resizable-node"
        :class="{
            'readonly': !editor?.isEditable,
            '!max-w-full': node.attrs.fullWidth,
            '!mx-24': node.attrs.fullWidth && editor.isEditable,
        }"
    >
        <Accordion type="single" collapsible>
            <AccordionItem value="item-1">
                <AccordionTrigger class="hover:no-underline pt-[5px]"><div class="text-gray-500 px-3 p-0 text-xs font-medium mb-1 text-base">TABLE OF CONTENTS</div></AccordionTrigger>
                <AccordionContent>
                    <div class="sidebar">
                        <div class="sidebar-options">
                            <div class="table-of-contents">
                                <template v-if="editor">
                                    <ToC :editor="editor" :items="items" />
                                </template>
                            </div>
                        </div>
                    </div>
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    </node-view-wrapper>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { NodeViewWrapper } from '@tiptap/vue-3';
import { useRoomPage } from '../../../../pages/RoomDetail/hooks/useRoomPage.js';
import ToC from '@/tiptap/components/ToC/ToC.vue';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/Components/ui/accordion';

const error = ref(false);
const props = defineProps({
    editor: Object,
    node: Object,
    editable: Boolean,
    form: Object,
    onSubmit: Function,
    updateAttributes: {
        type: Function,
        required: true,
    },
})

const items = ref([]);
onMounted(() => {
    window.addEventListener('toc-update', (e) => {
        items.value = e.detail;
    });

    props.editor.commands.insertContent('<p></p>');
})
const {
    editable,
} = useRoomPage();
const loading = ref(true);

</script>
<style lang="scss">
</style>
